.header-wrapper {
	--color-black-alpha-10: rgb(255, 0, 0);
	background-color: var(--color-white);
	box-shadow: var(--shadow-sm);
	left: 0;
	position: sticky;
	top: 0;
	width: 100%;
	z-index: 1000;
	
}

.header {
	align-items: center;
	display: flex;
	height: 6rem;
	justify-content: space-between;
}

.header-brand {
	align-items: center;
	display: flex;
	column-gap: 1rem;
	height: inherit;
}

@media screen and (orientation: portrait) {
	.header {
		height: 4rem;
	}

	.header-brand {
		height: 4rem;
	}
}

@media screen and (orientation: landscape) {
	.header {
		height: 4rem;
	}

	.header-brand {
		height: 4rem;
	}
}

@media screen and (min-width: 1280px) {
	.header {
		height: 6rem;
	}
}