.button {
	align-items: center;
	background-color: var(--color-primary);
	box-shadow: var(--shadow-md);
	color: var(--color-white);
	display: flex;
	font-size: 0.875rem;
	justify-content: center;
	padding-block: 0.55rem;
	padding-inline: 0.55rem;
	user-select: none;
}

.button:not(:disabled):active {
	background-color: var(--color-primary-dark);
	box-shadow: var(--shadow-sm);
}
.button:not(:disabled):hover {
	background-color: var(--color-primary-dark);
	transition-duration: 150ms;
	transition-timing-function: linear;
	/* transform: scale(1.02); */
}

.button:disabled {
	filter: contrast(0.5);
}
