.accordion-container div:not(div:last-child) {
	border-bottom: 1px solid #0000004d;
}

.accordion-container div:first-child {
	border-radius: 4px 4px 0px 0px;
}

.accordion-container div:last-child {
	border-radius: 0 0 8px 8px;
	border-bottom: none;
}
