picture.brand {
  aspect-ratio: auto;
  height: auto;
  width: 8rem;
}

picture.brand figcaption {
  display: none;
}

@media screen and (min-width: 1280px) {
  picture.brand {
    width: 10rem;
  }
}