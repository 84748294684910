.rounded-sm {
  border-radius: 8px;
}

.rounded-md {
  border-radius: 12px;
}

.rounded-lg {
  border-radius: 16px;
}

.rounded-xl {
  border-radius: 20px;
}

.rounded-2xl {
  border-radius: 24px;
}

.rounded-3xl {
  border-radius: 28px;
}

.rounded-4xl {
  border-radius: 32px;
}

.rounded-full {
  border-radius: 100%;
}