.header-navigation {
  display: none;
}

.header-navigation-menu {
  display: flex;
  align-items: center;
  height: 100%;
}

@media screen and (min-width: 1024px) {
  .header-navigation {
    display: flex;
    flex: 1;
    height: 100%;
    justify-content: flex-end;
  }
}