.text-uppercase {
  text-transform: uppercase;
}

.text-lowercase {
  text-transform: lowercase;
}

.text-capitalized ::first-letter {
  text-transform: uppercase;
}
.text-capitalized {
  text-transform: lowercase;
}
