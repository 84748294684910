.timeline {
	position: relative;
	width: 100%;
	margin-inline: auto;
	margin-block: 1rem;
	padding: 0;
	max-width: 900px;
}

.timeline::after {
	content: '';
	position: absolute;
	width: 4px;
	background-color: var(--color-primary);
	top: 0;
	bottom: 0;
	border-radius: 8px;
	left: 90%;
}

.timeline-item {
	padding-block: 1rem;
	padding-inline: 1rem;
	position: relative;
	background-color: white;
	box-shadow: 0 0px 4px 0px rgb(0 0 0 / 25%);
	border-radius: 8px;
	margin-bottom: 3rem;
	max-width: calc(75% - 2rem);
}

.timeline-item::after {
	content: '';
	position: absolute;
	width: 24px;
	height: 24px;
	background-color: var(--color-primary);
	top: calc(50% - 0.6em);
	border-radius: 100%;
}

.timeline-item:nth-child(odd)::after,
.timeline-item:nth-child(even)::after {
	right: calc(-118px);
}

.timeline-content {
	position: relative;
}

.timeline-time {
	font-size: 1.4rem;
	font-weight: bolder;
	position: absolute;
	right: -35%;
	top: calc(50% - 1rem);
}

@media screen and (min-width: 1024px) {
	.timeline::after {
		left: 50%;
	}

	.timeline-item {
		max-width: calc(50% - 2rem);
	}

	.timeline-item:nth-child(even) {
		left: 50%;
		margin-left: 32px;
	}

	.timeline-item:nth-child(odd)::after {
		left: calc(100% + 22px);
	}
	.timeline-item:nth-child(even)::after {
		left: unset;
		right: calc(100% + 18px);
	}

	.timeline-item:nth-child(odd) .timeline-time {
		left: calc(100% + 80px);
	}
	.timeline-item:nth-child(even) .timeline-time {
		right: calc(100% + 80px);
	}

	.timeline-time {
		top: calc(50% - 0.9rem);
	}
}
