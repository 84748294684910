:root {
	/*
		* @media (min-width: 640px) {} sm
		*	@media (min-width: 768px) {} md
		*	@media (min-width: 1024px) {} xl
		*	@media (min-width: 1280px) {} 2xl
		*	@media (min-width: 1536px) {} 3xl
		*
		*/

	--screen-width-size-xs: 0px;
	--screen-width-size-sm: 360px;
	--screen-width-size-md: 640px;
	--screen-width-size-lg: 768px;
	--screen-width-size-xl: 1280px;
	--screen-width-size-2xl: 1536px;
	--screen-width-size-3xl: 1920px;
	--screen-width-size-4xl: 2560px;

	/* pallet colors */
	--color-primary: rgb(237, 93, 93);
	/* --color-primary: #3BBDAA; */
	/* --color-primary-dark: rgba(202, 55, 55); */
	--color-primary-dark: #3BBDAA;
	--blindness-color: rgb(0, 0, 0);
	--color-white: rgb(255, 255, 255);
	--color-black: #222222;
	--color-black-alpha-sm: hsl(0, 0%, 10%, 5%);
	--color-black-alpha-md: hsl(0, 0%, 10%, 5%);

	--z-index-first: 1000;

	--details-arrow-color: var(--color-primary);

	/* shadows */
	--shadow-sm: 0px 1px 2px 0 var(--color-black-alpha-sm);
	--shadow-md: 0px 2px 4px 1px var(--color-black-alpha-md);
}
