/* @tailwind base;
@tailwind components;
@tailwind utilities; */

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import 'tailwindcss/screens';
@import 'tailwindcss/variants';

@import url('./_root.css');
@import url('./components/index.css');
@import url('./utils/index.css');

#youtube-videos::-webkit-scrollbar {
	display: none;
}

body
,.__next {
	/* scroll-behavior: smooth; */
	/* position: relative; */
	user-select: none;
}

body.off-canvas-active {
	/* overflow-y: hidden; */
	/* filter: brightness() */
}

:focus-within {
	outline-width: 1px;
	outline-color: var(--color-primary);
}

.accordion:last-child {
	border: none;
	border-bottom-left-radius: 8px;
	border-bottom-right-radius: 8px;
}

details {
	position: relative;
}

summary::after {
	content: '>';
	position: absolute;
	right: 0.5rem;
	font-weight: bolder;
	/* width: 2rem;
	height: 2rem; */
	transition: 50ms;
	/* color: var(--details-arrow-color); */
}

details[open] summary::after {
	transform: rotateZ(90deg);
}

@keyframes bounce {
  0% {
    transform: translateY(-25%);
    animation-timing-function: cubic-bezier(0.8,0,1,1);
  }
  50% {
    transform: none;
    animation-timing-function: cubic-bezier(0,0,0.2,1);
  }
	100% {
		transform: translateY(0%);
	}
}
.animate-bounce-slow {
  animation: bounce 2s 3 backwards;
}


@keyframes infinite-scroll-x {
	0% {
		transform: translateX(100vw);
	}
	100% {
		transform: translateX(-160vw);
	}
}
.infinite-scroll-x {
	transform: translateX(160vw);
	user-select: none;
	animation: infinite-scroll-x 60s linear infinite;
}

.infinite-scroll-x:hover {
	animation-play-state: paused;
	will-change: transform;
}